import Button from '../Button'

function Project({
    projectImage,
    projectTitle,
    projectDesc,
    btnContent,
    imageAlt,
    projectTags,
    btnUrl,
    target
}) {
    return (
        <div className='project-global__container'>
            <div className="project-view-container">
                <div className='project-view-container__img'>
                <img src={projectImage} alt={imageAlt}></img>
                </div>
            </div>
            <div className="project-desc-container">
                <h3>{projectTitle}</h3>
                <div className="project-tags-container">{projectTags}</div>
                <p className='project-desc__desc'>{projectDesc}</p>
                <Button content={btnContent} url={btnUrl}  btnTarget={target}/>
            </div>
        </div>
    )
}

export default Project
