function Modal({ content }) {
    return (
        <div className="modal-container modal-display">
            {content}
            <div className="bg-loading"></div>
        </div>
    )
}

export default Modal
