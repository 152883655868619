import { useRef, useState } from 'react'
// Import emailjs pour la gestion de l'envoi d'email via le formulaire
import emailjs from '@emailjs/browser'

//import components
import Header from '../components/Header'
import Footer from '../components/Footer'
import Button from '../components/Button'
import Title from '../components/Title'
import Tag from '../components/Tag'
import Input from '../components/Input'
import Modal from '../components/Modal'
import Social from '../components/Social'
import Project from '../components/Project'



// Import data
import { projectsList } from '../assets/data/projectsList'

// Images
import linkedIn from '../assets/images/social/linkedin-ico.webp'
import gitHub from '../assets/images/social/github-ico.webp'
import twitter from '../assets/images/social/twitter-ico.webp'

import './App.scss'

function App() {
    const [modal, setModal] = useState(false)
    const [formInvalid, setFormInvalid] = useState(false)
    const [userName, setUserName] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [userMessage, setUserMessage] = useState('')

// Confirmation envoi du mail
    const handleModalValidation = () => {
        setModal(!modal)
        setTimeout(() => {
            setModal(false)
        }, 3000)
    }
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault()
        if (userName || userEmail || userMessage !== '') {
            emailjs
                .sendForm('service_qwdpn3f', 'template_2u1pxpf', form.current, {
                    publicKey: '-2-MR0lq6v5dIiqE7',
                })
                .then(
                    () => {
                        handleModalValidation()
                        setUserName('')
                        setUserEmail('')
                        setUserMessage('')
                    },
                    (error) => {
                        console.log(error)
                    }
                )
        } else {
            // Message d'erreur si les inputs du formulaire sont vide
            setFormInvalid(true)
            setTimeout(() => {
                setFormInvalid(false)
            }, 3000)
        }
    }
    return (
        <>
            <Header
                link_1="#profil"
                link_1Name="profil"
                link_2="#projects"
                link_2Name="projets"
                link_3="#contact"
                link_3Name="contact"
            />
            {/*Modal qui apparait une fois l'email envoyé */}
            {modal && <Modal content="Message envoyé !" />}
            <section className="intro-container" id="profil">
                <h1>bonjour, je suis romain schittek</h1>
                <p>
                    Spécialisé dans le développement web mon objectif est de
                    concevoir des sites web et des applications web modernes.
                </p>
                <Button content="Projets" url="#projects" />
            </section>
            <section className="profile-container">
                <Title title="À propos" />
                <p className="profile-container__intro">
                    Découvrez ici plus de détails sur mon parcours, mes
                    compétences et les technologies que j'utilise.
                </p>
                <div className="profile-container__social">
                    <Social
                        link="https://www.linkedin.com/in/romain-schittek-b7b655300/"
                        imageSrc={linkedIn}
                        imageAlt="LinkedIn logo"
                        target="_blank"
                    />
                    <Social
                        link=""
                        imageSrc={twitter}
                        imageAlt="Twitter Logo"
                        target="_blank"
                    />
                    <Social
                        link="https://github.com/OrionJelly"
                        imageSrc={gitHub}
                        imageAlt="Github Logo"
                        target="_blank"
                    />
                </div>
                <div className="profile-section">
                    <div className="profile-container__desc">
                        <h2>Apprenez à me connaitre</h2>
                        <p className="profile-container__desc--content">
                            Je suis{' '}
                            <span className="text-bold">développeur web </span>
                            front-end avec pour objectif la conception d'
                            <span className="text-bold">applications web </span>
                            et de{' '}
                            <span className="text-bold">
                                sites web modernes{' '}
                            </span>{' '}
                            et <span className="text-bold">originaux</span>.
                            <br />
                            <br />
                            Après avoir acquis de l'
                            <span className="text-bold">
                                expérience professionnelle
                            </span>
                            , notamment en gérant une entreprise de pose de
                            revêtement céramique et en occupant un poste de chef
                            de partie en restauration, j'ai appris à être{' '}
                            <span className="text-bold">
                                attentif aux détails
                            </span>{' '}
                            et <span className="text-bold">méthodique</span>.
                            <br />
                            <br />
                            Aujourd'hui, à la suite d'une formation d'
                            <span className="text-bold">
                                Intégrateur web
                            </span>{' '}
                            chez{' '}
                            <span className="text-bold">OpenClassrooms</span> je
                            suis de retour à ma passion première, qui est le
                            domaine du web, en particulier la conception.
                        </p>
                        <Button content="Contact" url="#contact" />
                    </div>
                    <div className="skill-container">
                        <h2>Mes compétences</h2>
                        <Tag tagName="HTML" />
                        <Tag tagName="CSS" />
                        <Tag tagName="JavaScript" />
                        <Tag tagName="React" />
                        <Tag tagName="Wordpress" />
                        <Tag tagName="SEO" />
                    </div>
                </div>
            </section>
            <section className="projects-container" id="projects">
                <Title title="Projets" />
                <p className="projects-container__desc">
                    Vous trouverez ici une partie des projets que j'ai réalisés,
                    qu'ils soient personnels ou pour des clients.
                </p>
                {/* Retourne les projets de la projectlist */}
                {projectsList.map((projects) => (
                    <Project
                        key={projects.id}
                        projectTitle={projects?.title}
                        projectTags={projects.tags.map((project, i) => (
                            <Tag tagName={project} key={`t-${i}`} />
                        ))}
                        projectImage={projects?.cover}
                        projectDesc={projects?.description}
                        imageAlt={projects?.imageAlt}
                        btnUrl={projects.url}
                        btnContent="Voir"
                        target="_blank"
                    />
                ))}
            </section>
            <section className="contact-container" id="contact">
                <Title title="Contact" />
                <p className="contact-container__desc">
                    Je suis disponible pour vous aider à concrétiser vos
                    projets. Envoyez-moi un message, je vous répondrai dès que
                    possible.
                </p>

                <form
                    ref={form}
                    onSubmit={sendEmail}
                    className="form-container"
                >
                    <Input
                        htmlFor="name"
                        label="Nom"
                        placeholder="Entrez votre nom"
                        type="text"
                        name="name"
                        id="name"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                    />
                    <Input
                        htmlFor="email"
                        label="Email"
                        placeholder="Entrez votre email"
                        type="email"
                        name="email"
                        id="email"
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                    />
                    <div className="message-container">
                        <label htmlFor="message">Message</label>
                        <textarea
                            id="message"
                            name="message"
                            placeholder="Entrez votre message"
                            value={userMessage}
                            onChange={(e) => setUserMessage(e.target.value)}
                        ></textarea>
                    </div>
                    {/* Vérification des champs du formulaire */}
                    {formInvalid && (
                        <p className="error-form ">
                            Veuillez vérifier les champs du formulaire.
                        </p>
                    )}
                    <Button btn_type="submit" content="Envoyer" />
                </form>
            </section>
            <Footer />
        </>
    )
}

export default App
