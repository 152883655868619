

import argentBank from '../images/projects/argentbank-view-550.webp';
import ninaView from '../images/projects/nina-view-550.webp';
import kasaView from '../images/projects/kasa-view-550.webp';

export const projectsList = [
    {
        id: '0',
        title: 'ArgentBank',
        description:
            "Développement front-end d'une application bancaire à l'aide de Reactjs et Redux, écriture d'API REST pour connecter les deux parties de l'application. Redux a été utilisé pour la gestion de l'authentification et des données utilisateurs. Swagger a été utilisé pour modéliser les interactions avec les données des transactions.",
        url: 'https://github.com/OrionJelly/ArgentBank',
        cover: `${argentBank}`,
        imageAlt: 'ArgentBank application bancaire',
        tags: ['React', 'React-router', 'Redux'],
    },
    {
        id: '1',
        title: 'Kasa',
        description:
            "Développement front-end d'une application web moderne et interactives de location immobilière, à l'aide de Reactjs et React router.",
        url: 'https://github.com/OrionJelly/kasa',
        cover: `${kasaView}`,
        imageAlt: 'Kasa agence location immobilière ',
        tags: ['React', 'React-router'],
    },
    {
        id: '2',
        title: 'Nina Carducci',
        description:
            "Optimisation du référencement d'un site web sur les moteurs de recherche. Amélioration des performances ainsi que de l'accessibilité, utilisation balise openGraph. Rédaction d'un rapport de performances et d'accessibilité après optimisation.",
        url: 'https://github.com/OrionJelly/ninacarducci_portfolio',
        cover: `${ninaView}`,
        imageAlt: 'Nina Carducci portfolio photographe artiste',
        tags: ['SEO', 'Accessibilité', 'Optimisation'],
    },
    
]