

import { useEffect, useState } from 'react'
import Logo from '../../assets/images/comete.svg'
import Hamburger from '../../assets/images/grid-icon.svg'


function Header({link_1, link_2, link_3, link_1Name, link_2Name, link_3Name }) {

    const [width, setWidth] = useState(window.innerWidth)
    const [toggle, setToggle] = useState(false)
    const toggleState = () => {
        setToggle(!toggle)
    }
// Listener de la largeur d'écran
    useEffect(() => {
        function updateWidth() {
            setWidth(window.innerWidth)

        }
        window.addEventListener('resize', updateWidth)
    }, [])


    return (
        <>
            <header className="header-container">
                <nav className="header-container__nav">
                    <div className="header-container__nav--logo">
                        <img src={Logo} alt="Logo" />
                        <p>romain schittek</p>
                    </div>
                    <div className="header-container__link">
                        {width < 720 ? (
                            <>
                                <img
                                    src={Hamburger}
                                    alt="Menu"
                                    onClick={toggleState}
                                />
                            </>
                        ) : (
                            <div className='header-container__link--desktop'>
                                <a href={link_1}>{link_1Name}</a>
                                <a href={link_2}>{link_2Name}</a>
                                <a href={link_3}>{link_3Name}</a>
                            </div>
                        )}
                    </div>
                </nav>
            </header>

            <div className={toggle ? 'mobile-menu__container' : 'menu-hidden'}>
                <a href={link_1} onClick={toggleState}>
                    {link_1Name}
                </a>
                <a href={link_2} onClick={toggleState}>
                    {link_2Name}
                </a>
                <a href={link_3} onClick={toggleState}>
                    {link_3Name}
                </a>
            </div>
        </>
    )
}

export default Header