function Title ({title}) {

    return (
        <div className="title-container">
        <h2>
            {title}
        </h2>
        <div className="title-deco"></div>
        </div>
    )
}

export default Title