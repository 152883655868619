import Social from "../Social"
// Import des images pour les réseaux sociaux
import linkedIn from '../../assets/images/social/linkedin-ico.webp'
import gitHub from '../../assets/images/social/github-ico.webp'
import twitter from '../../assets/images/social/twitter-ico.webp'

function Footer () {

    return (
        <footer className="footer-container">
            <div className="footer-container__social">
                <h2 className="footer-container__social--title">réseaux</h2>
                <Social
                    link="https://www.linkedin.com/in/romain-schittek-b7b655300/"
                    imageSrc={linkedIn}
                    imageAlt="LinkedIn logo"
                    target="_blank"
                />
                <Social
                    link=""
                    imageSrc={twitter}
                    imageAlt="Twitter Logo"
                    target="_blank"
                />
                <Social
                    link="https://github.com/OrionJelly"
                    imageSrc={gitHub}
                    imageAlt="Github Logo"
                    target="_blank"
                />
            </div>
            <div className="footer-container__profile">
                <h3 className="footer-container__profile--title">
                    romain schittek
                </h3>
                <p>
                    Spécialisé dans le développement web mon objectif est de
                    concevoir des sites web et des applications web modernes.
                </p>
            </div>
            <div className="footer-container__copyright">
                <p>© Copyright 2024. Made by Romain Schittek</p>
            </div>
        </footer>
    )
}

export default Footer